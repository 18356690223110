import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setToken } from "../redux/userSlice";
import { setNavigator } from "../api/api";

export default function useLoggedIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  useEffect(() => {
    setNavigator(navigate);
  }, [navigate]);

  useEffect(() => {
    if (!token) {
      navigate("/login", { replace: true });
    } else {
      dispatch(setToken(token));
    }
  }, [token]);
}
