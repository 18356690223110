import { TextField } from "@mui/material";
import React from "react";

const SearchInput = ({ placeholder, clasaName, query, setQuery, onSubmit }) => {
  return (
    <div>
      <TextField
        variant="outlined"
        placeholder={placeholder}
        InputProps={{
          style: {
            height: "40px",
            width: "280px",
          },
        }}
        value={query}
        className={clasaName}
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSubmit();
          }
        }}
      />
    </div>
  );
};

export default SearchInput;
