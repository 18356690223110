import { Typography } from "@mui/material";
import React from "react";
import Layout from "./Layout";

const Settings = () => {
  return (
    <Layout item={4}>
      <Typography>Settings</Typography>
    </Layout>
  );
};

export default Settings;
