import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatDate } from "../../utils/helpers";
import { StyledTableCell, StyledTableRow } from "../common/Styled";

const SignalsHistoryTable = ({ stocks }) => {
  return (
    <TableContainer component={Paper} className="mt-8">
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Month</StyledTableCell>
            <StyledTableCell>Stock-1</StyledTableCell>
            <StyledTableCell>Stock-2</StyledTableCell>
            <StyledTableCell>Stock-3</StyledTableCell>
            <StyledTableCell>Stock-4</StyledTableCell>
            <StyledTableCell>Stock-5</StyledTableCell>
            <StyledTableCell>Stock-6</StyledTableCell>
            <StyledTableCell>Stock-7</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stocks?.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>{formatDate(row?.createdAt)}</StyledTableCell>
              {row?.stockDetails?.map((stockDetail, index) => (
                <StyledTableCell key={index}>
                  {stockDetail?.symbol}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SignalsHistoryTable;
