import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import BoldText from "../components/typography/BoldText";
import SearchInput from "../components/common/SearchInput";
import StocksTable from "../components/stocks/StocksTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllStock } from "../redux/stocksSlice";

const Stocks = () => {
  const [query, setQuery] = useState("");
  const { allStockDetails } = useSelector((state) => state.stocks);
  const [filteredStocks, setFilteredStocks] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllStock());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allStockDetails?.length > 0) setFilteredStocks(allStockDetails);
  }, [allStockDetails]);

  useEffect(() => {
    if (query) {
      const filtered = allStockDetails?.filter((stock) =>
        stock.symbol.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredStocks(filtered);
    } else {
      setFilteredStocks(allStockDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  return (
    <Layout item={3}>
      <div className="p-10">
        <BoldText className={"text-4xl"}>Stocks</BoldText>
        <div className="mt-6">
          <Typography>Shariah Compliant Stocks</Typography>
        </div>
        <div className="mt-6">
          <SearchInput
            placeholder={"Search Stocks"}
            query={query}
            setQuery={setQuery}
            onSubmit={() => console.log(query)}
          />
        </div>
        <div className="mt-6">
          <StocksTable stocks={filteredStocks} />
        </div>
      </div>
    </Layout>
  );
};

export default Stocks;
