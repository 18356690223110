import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import BoldText from "../components/typography/BoldText";
import ButtonSmall from "../components/signals/ButtonSmall";
import { useTheme } from "@emotion/react";
import SignalSearchBar from "../components/signals/SignalSearchBar";
import Stocks from "../components/signals/Stocks";
import ButtonLarge from "../components/auth/ButtonLarge";
import { useDispatch, useSelector } from "react-redux";
import { createMonthlyStock, fetchMonthly } from "../redux/stocksSlice";
import { toast } from "react-toastify";
import AlertDialog from "../components/common/AlertDialog";
import SignalsHistoryTable from "../components/signals/SignalsHistoryTable";

const MonthlySignals = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { stockDetails, monthlyBuyStocks } = useSelector(
    (state) => state.stocks
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchMonthly());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createStock = () => {
    if (!stockDetails || stockDetails.length < 7) {
      toast.error("Please select seven stocks");
      return;
    }
    // dispatch(createMonthlyStock({ stockDetails }));
    setOpen(true);
  };

  return (
    <Layout item={2}>
      <div className="p-10">
        <BoldText className={"text-4xl"}>Monthly Signals</BoldText>
        <div className="mt-6">
          <ButtonSmall>Buy Signals</ButtonSmall>
          <ButtonSmall color={theme.palette.primary.light}>
            Sell Signals
          </ButtonSmall>
        </div>
        <SignalSearchBar />
        <Stocks />
        <ButtonLarge onClick={createStock} width={"150px"}>
          Proceed
        </ButtonLarge>
        <div className="mt-6">
          <SignalsHistoryTable stocks={monthlyBuyStocks} />
        </div>
      </div>
      <AlertDialog
        message={
          "This action will automatically buy stocks for users who have enabled auto-execution"
        }
        header={"Are you sure?"}
        open={open}
        setOpen={setOpen}
        cancelText={"Cancel"}
        proceedText={"Proceed"}
        proceedFn={() => dispatch(createMonthlyStock({ stockDetails }))}
      />
    </Layout>
  );
};

export default MonthlySignals;
