import { Typography } from "@mui/material";
import React from "react";

const BoldText = ({ children, className, color, sx }) => {
  return (
    <Typography
      className={`${className}`}
      style={{ fontFamily: "Poppins Bold" }}
      color={color}
      sx={sx}
    >
      {children}
    </Typography>
  );
};

export default BoldText;
