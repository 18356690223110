import { Typography } from "@mui/material";
import React from "react";
import Layout from "./Layout";

const Home = () => {
  return (
    <Layout item={0}>
      <Typography>Home</Typography>
    </Layout>
  );
};

export default Home;
