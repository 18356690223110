import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { truncate } from "../../utils/helpers";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { StyledTableCell, StyledTableRow } from "../common/Styled";
import { Switch } from "@mui/material";
import AlertDialog from "../common/AlertDialog";
import { useDispatch } from "react-redux";
import { updateStockDetails } from "../../redux/stocksSlice";

const StocksTable = ({ stocks }) => {
  const [open, setOpen] = React.useState(false);
  const [selectedStock, setSelectedStock] = React.useState({});
  const dispatch = useDispatch();

  const handleChange = (row) => {
    setSelectedStock(row);
    setOpen(true);
  };

  return (
    <TableContainer component={Paper} className="mt-8">
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Stock</StyledTableCell>
            <StyledTableCell>Exchange</StyledTableCell>
            <StyledTableCell>Symbol</StyledTableCell>
            <StyledTableCell>
              Status <KeyboardArrowDownIcon />
            </StyledTableCell>
            <StyledTableCell align="right">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stocks?.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>{truncate(row?.name, 30)}</StyledTableCell>
              <StyledTableCell>{row?.exchange}</StyledTableCell>
              <StyledTableCell>{row?.symbol}</StyledTableCell>
              <StyledTableCell>
                {row?.isCompliant ? "Compliant" : "Non-Compliant"}
              </StyledTableCell>
              <StyledTableCell align="right">
                <Switch
                  checked={row?.isCompliant}
                  onChange={() => handleChange(row)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <AlertDialog
        message={`Are you sure you want to set this stock as ${
          selectedStock?.isCompliant ? "Non-Compliant" : "Compliant"
        }`}
        header={"Are you sure?"}
        open={open}
        setOpen={setOpen}
        cancelText={"Cancel"}
        proceedText={"Proceed"}
        proceedFn={() =>
          dispatch(
            updateStockDetails({
              symbol: selectedStock?.symbol,
              isCompliant: !selectedStock?.isCompliant,
            })
          )
        }
      />
    </TableContainer>
  );
};

export default StocksTable;
