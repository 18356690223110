import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatDate } from "../../utils/helpers";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { StyledTableCell, StyledTableRow } from "../common/Styled";

const DataTable = ({ users }) => {
  return (
    <TableContainer component={Paper} className="mt-8">
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>id</StyledTableCell>
            <StyledTableCell>
              Name <KeyboardArrowDownIcon />
            </StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell>
              Reg. Date <KeyboardArrowDownIcon />
            </StyledTableCell>
            <StyledTableCell>Verified</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>{index + 1}</StyledTableCell>
              <StyledTableCell>{row.fullName}</StyledTableCell>
              <StyledTableCell>{row.email}</StyledTableCell>
              <StyledTableCell>{formatDate(row.createdAt)}</StyledTableCell>
              <StyledTableCell>{row.isVerified ? "Yes" : "No"}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
