import React from "react";
import LeftNav from "../components/home/LeftNav";
import useLoggedIn from "../hooks/useLoggedIn";

const Layout = ({ children, item }) => {
  useLoggedIn();
  return (
    <div className="flex flex-row">
      <aside className="w-72">
        <LeftNav item={item} />
      </aside>
      <main className="w-10/12">{children}</main>
    </div>
  );
};

export default Layout;
