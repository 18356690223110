import React from "react";
import consultingService from "../assets/images/consultingService.png";
import halalLogo from "../assets/images/halalLogo.svg";
import SignInForm from "../components/auth/SignInForm";
import { useTheme } from "@mui/material";

const Login = () => {
  const theme = useTheme();
  return (
    <main
      className=" lg:h-screen"
      style={{
        backgroundColor: theme.palette.background.main,
        height: "133vh",
      }}
    >
      <section className=" container mx-auto">
        <header className=" pt-8 ml-12">
          <img src={halalLogo} alt="Halal Logo" />
        </header>
        <main className=" mx-auto w-full flex flex-row items-center justify-center mt-28">
          <div className="w-1/2 mt-20 hidden lg:block">
            <img src={consultingService} alt="Consulting Service" />
          </div>
          <SignInForm />
        </main>
      </section>
    </main>
  );
};

export default Login;
