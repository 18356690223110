import React, { useEffect } from "react";
import Layout from "./Layout";
import BoldText from "../components/typography/BoldText";
import SearchBar from "../components/users/SearchBar";
import DataTable from "../components/users/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../redux/userSlice";

const Users = () => {
  const { users } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return (
    <Layout item={1}>
      <div className="p-10">
        <BoldText className={"text-4xl"}>Users</BoldText>
        <SearchBar length={users?.length} />
        <DataTable users={users} />
      </div>
    </Layout>
  );
};

export default Users;
