import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateLoadingToast } from "../utils/helpers";
import { toast } from "react-toastify";
import { authLogin, fetchUsers } from "../api/api";

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ data, onSuccess }, { rejectWithValue, dispatch }) => {
    try {
      toast.loading("Logging in...", { toastId: "login-user" });
      const res = await authLogin(data);
      dispatch(setUser(res.user));
      dispatch(setToken(res.token));
      dispatch(setUserPreferences(res.preferences));
      localStorage.setItem("token", res.token);
      onSuccess?.();
      return res.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getUsers = createAsyncThunk(
  "auth/fetchUsers",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      toast.loading("Fetching users", { toastId: "fetch-users" });
      const res = await fetchUsers();
      dispatch(setUsers(res));
      return res.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    token: null,
    userPreferences: null,
    users: [],
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUserPreferences: (state, action) => {
      state.userPreferences = action.payload;
    },
    resetUser: (state, action) => {
      state.user = null;
      state.token = null;
      state.userPreferences = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      toast.dismiss("login-user");
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      updateLoadingToast(
        action.payload ?? "Error logging in",
        "error",
        "login-user"
      );
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      toast.dismiss("fetch-users");
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      updateLoadingToast(
        action.payload ?? "Error fetching users",
        "error",
        "fetch-users"
      );
    });
  },
});

export const { setUser, setToken, setUserPreferences, resetUser, setUsers } =
  userSlice.actions;

export default userSlice.reducer;
