import { Button, useTheme } from "@mui/material";
import React from "react";

const ButtonLarge = ({ children, sx, className, type, width, onClick }) => {
  const theme = useTheme();
  return (
    <Button
      type="submit"
      onClick={() => onClick?.()}
      sx={{
        background: `linear-gradient(90deg, ${theme.palette.gradient.main}, ${theme.palette.gradient.light})`,
        width: width ?? "92%",
        height: "50px",
        borderRadius: "10px",
        marginTop: "30px",
        fontSize: "18px",
        fontWeight: "bold",
        textTransform: "none",
        ...sx,
      }}
      className={className}
      variant="contained"
    >
      {children}
    </Button>
  );
};

export default ButtonLarge;
