import { ThemeProvider, createTheme } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { store } from "./redux/store";
import router from "./routes/router";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1f2f49",
      light: "#000",
    },
    secondary: {
      main: "#FA8C06",
      dark: "#013556",
      bgGrey: "#F6F6F6",
    },
    background: {
      main: "#354257",
    },
    gradient: {
      main: "#db811e",
      light: "#efd733",
    },
    text: {
      white: "#FFFFFF",
      lightBlue: "#8CBCDB",
      aboutBlue: "#3179AF",
      skyblue: "#D8E8F2",
      caption: "#A7A7A7",
      textGrey: "#4F4F4F",
    },
    footer: {
      main: "#072136",
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      xxl: 1536,
      xlg: 1950,
      xxlg: 2560,
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
        <ToastContainer position="bottom-center" />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
