import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import stocksSlice from "./stocksSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    stocks: stocksSlice,
  },
});
